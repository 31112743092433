{
  "levels": [
    {
      "naturfag": [
        {
          "fag": "Naturfag",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-01.png",
          "q": "Hvor skal du gøre af sæbevand?",
          "a": [
            {
              "option": "Risten i vejen",
              "correct": false
            },
            {
              "option": "Toilettet",
              "correct": true
            },
            {
              "option": "Græsplænen",
              "correct": false
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-02.png",
          "q": "Hvor kommer vandet fra risten i vejen hen?",
          "a": [
            {
              "option": "Renseanlæg",
              "correct": false
            },
            {
              "option": "Bækken",
              "correct": true
            },
            {
              "option": "Marken",
              "correct": false
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-03.png",
          "q": "Hvilket dyr kan ikke lide sæbevand?",
          "a": [
            {
              "option": "Fisk",
              "correct": true
            },
            {
              "option": "Krage",
              "correct": false
            },
            {
              "option": "Skorpion",
              "correct": false
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-13.png",
          "q": "Hvor skal man vaske sin bil?",
          "a": [
            {
              "option": "Indkørslen",
              "correct": false
            },
            {
              "option": "På vejen",
              "correct": false
            },
            {
              "option": "I vaskehal",
              "correct": true
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-04.png",
          "q": "Må du putte affald som tyggegummi i risten i vejen?",
          "a": [
            {
              "option": "Ja",
              "correct": false
            },
            {
              "option": "Nej",
              "correct": true
            }
          ]
        }
      ],
      "dansk": [
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-05.png",
          "q": "Hvilket dyr starter med F?",
          "a": [
            {
              "option": "Fisk",
              "correct": true
            },
            {
              "option": "Ko",
              "correct": false
            },
            {
              "option": "Abe",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-06.png",
          "q": "Hvilket af følgende ord kan du sætte “et” foran?",
          "a": [
            {
              "option": "Boble",
              "correct": false
            },
            {
              "option": "Bil",
              "correct": false
            },
            {
              "option": "Bord",
              "correct": true
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-07.png",
          "q": "Hvad rimer på vand?",
          "a": [
            {
              "option": "Spand",
              "correct": true
            },
            {
              "option": "Sæbe",
              "correct": false
            },
            {
              "option": "Fisk",
              "correct": false
            }
          ]
        }
      ],
      "matematik": [
        {
          "fag": "Matematik",
          "video": "https://www.youtube.com/embed/eKRVZgiCzMM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-08.png",
          "q": "Hvor mange skumpletter er der på anden?",
          "a": [
            {
              "option": "1",
              "correct": false
            },
            {
              "option": "2",
              "correct": true
            },
            {
              "option": "3",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "https://www.youtube.com/embed/eKRVZgiCzMM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-09.png",
          "q": "Hvor mange biler holder i kø for at blive vasket i vaskehal?",
          "a": [
            {
              "option": "1",
              "correct": false
            },
            {
              "option": "2",
              "correct": false
            },
            {
              "option": "3",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "https://www.youtube.com/embed/eKRVZgiCzMM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-10.png",
          "q": "Hvor mange fisk bliver glade for, at der kun kommer regnvand i kloakken?",
          "a": [
            {
              "option": "2",
              "correct": false
            },
            {
              "option": "3",
              "correct": false
            },
            {
              "option": "4",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "https://www.youtube.com/embed/eKRVZgiCzMM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-11.png",
          "q": "Hvor mange kemikaliedunke skal afleveres til genbrugspladsen?",
          "a": [
            {
              "option": "7",
              "correct": false
            },
            {
              "option": "9",
              "correct": true
            },
            {
              "option": "11",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "https://www.youtube.com/embed/eKRVZgiCzMM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "illustrations-12.png",
          "q": "Hvor mange spade med vand fra vinduesvask skal hr. Olsen tømme?",
          "a": [
            {
              "option": "5",
              "correct": false
            },
            {
              "option": "6",
              "correct": false
            },
            {
              "option": "7",
              "correct": true
            }
          ]
        }
      ]
    },
    {
      "naturfag": [
        {
          "fag": "Naturfag",
          "video": "https://vandetsvej.dk/faglig-viden/kloak/grundviden/typer-kloaksystemer",
          "videotekst": "Se Typer af kloaksystemer | Vandets Vej",
          "image": "Naturfag-2-1.jpg",
          "q": "Hvad betyder det, at der er separatkloakeret?",
          "a": [
            {
              "option": "Vand inde fra huset og i vejriste leder til renseanlæg",
              "correct": false
            },
            {
              "option": "Regnvand leder til vandløb, og vand inde fra huset løber til renseanlæg",
              "correct": true
            },
            {
              "option": "Vand inde fra huset og i vejriste leder til vandløb",
              "correct": false
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://vandetsvej.dk/faglig-viden/vandmiljoe/grundviden/badevand",
          "videotekst": "Se Badevand | Vandets Vej",
          "image": "Naturfag-2-2.jpg",
          "q": "Påvirker det mennesker, når man hælder forurenet vand i vejriste?",
          "a": [
            {
              "option": "Nej, det går kun ud over fisk og planter",
              "correct": false
            },
            {
              "option": "Ja, vandet kommer ud i fjorden, hvor mennesker bader",
              "correct": true
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "https://vandetsvej.dk/faglig-viden/vandmiljoe/grundviden/vandmiljoeet-er-saarbart",
          "videotekst": "Se Vandmiljøet er sårbart | Vandets Vej",
          "image": "Naturfag-2-3.jpg",
           "q": "Hvad sker der, hvis du leder sæbevand eller lignende til vandløbet via vejriste?",
          "a": [
            {
              "option": "Det sker der ikke noget ved",
              "correct": false
            },
            {
              "option": "Det bliver blandet med vandet i vandløbet uden anden påvirkning",
              "correct": false
            },
            {
              "option": "Det skader planter og dyr i vandløbet",
              "correct": true
            }
          ]
        },

        {
          "fag": "Naturfag",
          "video": "https://minkloak.dk/?msclkid=9d666389b66311ec8fd4b241e55d2177",
          "videotekst": "Din Kloak",
          "image": "Naturfag-2-4.jpg",
          "q": "Er der separatkloakeret, der hvor du bor?",
          "a": [
            {
              "option": "Ja",
              "correct": true
            },
            {
              "option": "Nej",
              "correct": true
            }
          ]
        }
      ],
      "dansk": [
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-1.jpg",
          "q": "Hvilken fortællertype er der i eventyret?",
          "a": [
            {
              "option": "Jeg-fortæller",
              "correct": false
            },
            {
              "option": "Synlig 3. persons-fortæller",
              "correct": true
            },
            {
              "option": "Skjult 3. persons-fortæller",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-2.jpg",
          "q": "Angiv hvilke af følgende ord fra eventyret, der er et navneord:",
          "a": [
            {
              "option": "Beskytte",
              "correct": false
            },
            {
              "option": "Dejlig",
              "correct": false
            },
            {
              "option": "Bæk",
              "correct": true
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-3.jpg",
          "q": "Hvad er moralen i eventyret?",
          "a": [
            {
              "option": "At forurening af bækken er godt",
              "correct": false
            },
            {
              "option": "At regnvandskloakken kun er til regnvand",
              "correct": true
            },
            {
              "option": "At der ikke må komme vand i risten, så du skal sidde med en paraply over risten",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-4.jpg",
          "q": "Hvorfor er fisken helten i eventyret?",
          "a": [
            {
              "option": "Fordi den står ved, hvad der er rigtigt",
              "correct": true
            },
            {
              "option": "Fordi den godt kan lide at svømme",
              "correct": false
            },
            {
              "option": "Fordi den kan snakke med mennesker",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-5.jpg",
          "q": "Hvilket tema behandler eventyret?",
          "a": [
            {
              "option": "Kærlighed",
              "correct": false
            },
            {
              "option": "Uvidenhed",
              "correct": true
            },
            {
              "option": "Magt",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-6.jpg",
          "q": "Hvilket ord passer ind? Vand med kemikalier ______ på genbrugspladsen",
          "a": [
            {
              "option": "Vand med kemikalier afleveres på genbrugspladsen",
              "correct": true
            },
            {
              "option": "Vand med kemikalier avleveres på genbrugspladsen",
              "correct": false
            },
            {
              "option": "Vand med kemikalier aveleveres på genbrugspladsen",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-7.jpg",
          "q": "Hvad gør hr. Olsen, som er dårligt for naturen?",
          "a": [
            {
              "option": "Hælder vaskevand i toilettet",
              "correct": false
            },
            {
              "option": "Afleverer kemikalier på genbrugspladsen",
              "correct": false
            },
            {
              "option": "Vasker bil, så vandet løber i regnvandskloakken",
              "correct": true
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-8.png",
          "q": "Hvor skal hr. Olsen vaske sin bil?",
          "a": [
            {
              "option": "På vejen",
              "correct": false
            },
            {
              "option": "I vaskehal",
              "correct": true
            },
            {
              "option": "I skoven",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-9.jpg",
          "q": "Find forholdsordet i sætningen: 'Husk på, at der kun må komme vand uden kemikalier ned i regnvandskloakken, nå ja og regnvand naturligvis.'",
          "a": [
            {
              "option": "Ned",
              "correct": true
            },
            {
              "option": "Kun",
              "correct": false
            },
            {
              "option": "Vand",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-2-10.jpg",
          "q": "Hvor mange stavelser er der i 'regnvandskloak'?",
          "a": [
            {
              "option": "4",
              "correct": true
            },
            {
              "option": "6",
              "correct": false
            },
            {
              "option": "7",
              "correct": false
            }
          ]
        }
      ],
      "matematik": [
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-1.jpg",
          "q": "Hvis en regnvandsrist er 20 cm lang og 20 cm bred, hvad er arealet af risten så?",
          "a": [
            {
              "option": "200 cm<sup>2</sup>",
              "correct": false
            },
            {
              "option": "80 cm<sup>2</sup>",
              "correct": true
            },
            {
              "option": "400 cm<sup>2</sup>",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-2.jpg",
          "q": "Hvis du bruger 5 L vand til at vaske vinduer med og blander 0,5 L sæbe i, hvor meget vand skal så hældes i toilettet?",
          "a": [
            {
              "option": "5 L",
              "correct": false
            },
            {
              "option": "5,1 L",
              "correct": false
            },
            {
              "option": "5,5 L",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-3.png",
          "q": "For at passe på vandløbet køres jeres bil i vaskehal. Der er 3015 m til vaskehallen. For at finde ud af hvor langt bilen kører i alt, skal du plusse 3015 m ud til vaskehallen med 3015 m tilbage igen. Hvad bliver det?",
          "a": [
            {
              "option": "3030 m",
              "correct": false
            },
            {
              "option": "5015 m",
              "correct": false
            },
            {
              "option": "6030 m",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "illustrations-18.png",
          "q": "Fisken skal spise 530 insekter om ugen. Der er kun 121 insekter på grund af vand med kemikalier. Ved at minus 121 fra 530 får du, hvor mange insekter den mangler. Vælg det rigtige svar.",
          "a": [
            {
              "option": "409",
              "correct": true
            },
            {
              "option": "429",
              "correct": false
            },
            {
              "option": "501",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-5.jpg",
          "q": "Genbrugspladsen lukker klokken 18.45. Det vil du fortælle til din nabo, der skal aflevere kemikalier. Hvornår siger du, genbrugspladsen lukker?",
          "a": [
            {
              "option": "Kvart over seks",
              "correct": false
            },
            {
              "option": "Halv syv",
              "correct": false
            },
            {
              "option": "Kvart i syv",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-6.jpg",
          "q": "Det regner mest i februar, marts og april. Hvilket månedsnummer har april?",
          "a": [
            {
              "option": "2",
              "correct": false
            },
            {
              "option": "4",
              "correct": true
            },
            {
              "option": "5",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-2-7.jpg",
          "q": "Det tager Hr. Olsen ca. 1 time at vaske vinduer. Hvorefter han hælder vandet i toilettet. Hvor mange minutter er der på en time?",
          "a": [
            {
              "option": "50 min",
              "correct": false
            },
            {
              "option": "60 min",
              "correct": true
            },
            {
              "option": "100 min",
              "correct": false
            }
          ]
        }
      ]
    },
    {
      "naturfag": [
        {
          "fag": "Naturfag",
          "video": "https://vandetsvej.dk/faglig-viden/vandmiljoe/noerdviden/tungmetaller-miljoefremmede-stoffer",
          "videotekst": "Se Tungmetaller og miljøfremmede stoffer | Vandets Vej",
          "image": "illustrations-13.png",
          "q": "Hvad sker der, hvis ukrudt i eks. indkørslen fjernes med pesticider?",
          "a": [
            {
              "option": "Kemikaliet ledes med regnvandet til vandløbet",
              "correct": true
            },
            {
              "option": "Pesticiderne er væk inden der kommer regnvejr",
              "correct": false
            },
            {
              "option": "Pesticider flytter sig ikke med vandet",
              "correct": false
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "",
          "videotekst": "",
          "image": "Naturfag-3-1.jpg",
          "q": "Hvorfor er det vigtigt, at vi tænker over, hvad vi kommer i kloakken via vejriste?",
          "a": [
            {
              "option": "Fordi det er træls at tabe noget, der er svært at få op igen",
              "correct": false
            },
            {
              "option": "For at vi får tilført nok næring til vandløbet",
              "correct": false
            },
            {
              "option": "For at fastholde og forbedre tilstanden i vandløbet og levevilkårene for smådyr, planter og fisk",
              "correct": true
            }
          ]
        },
        {
          "fag": "Naturfag",
          "video": "",
          "videotekst": "",
          "image": "Naturfag-3-2.jpg",
          "q": "Hvis der kommer olie fra bilvask eller lækkende biler i regnvandsristen, hvad sker der så med de fugle, som kommer i kontakt med det?",
          "a": [
            {
              "option": "De bliver blødere, fordi fjerene smøres ligesom med creme",
              "correct": false
            },
            {
              "option": "De er hurtige at tilberede, fordi de er mere antændelige",
              "correct": false
            },
            {
              "option": "De dør, fordi det påvirker vandtætning og placering af fjerene, så de f.eks. fryser ihjel eller drukner",
              "correct": true
            }
          ]
        }
      ],
      "dansk": [
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-3-1.jpg",
          "q": "Hvilken form har navneordet 'regnvandskloakken'?",
          "a": [
            {
              "option": "Bestemt form",
              "correct": true
            },
            {
              "option": "Ubestemt form",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-3-2.jpg",
          "q": "Hvilke forureningskilder (steder vand med kemikalier kommer fra) er angivet i eventyret?",
          "a": [
            {
              "option": "Bilvask og vand fra nedløbsrøret",
              "correct": false
            },
            {
              "option": "Bilvask og rengøring af havemøbler",
              "correct": true
            },
            {
              "option": "Rengøring af havemøbler og regnvand",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-3-3.jpg",
          "q": "Angiv den rigtige placering af punktum i sætningen: 'Regnvandskloakken langs vejen er kun til vand uden kemikalier sæbevand skal skylles ud i toilettet og maling, olie og kemikalierester skal afleveres på genbrugspladsen'",
          "a": [
            {
              "option": "'Regnvandskloakken langs vejen er kun til vand. Uden kemikalier sæbevand skal skylles ud i toilettet og maling, olie og kemikalierester skal afleveres på genbrugspladsen.'",
              "correct": false
            },
            {
              "option": "'Regnvandskloakken langs vejen er kun til vand uden kemikalier sæbevand skal skylles. Ud i toilettet og maling, olie og kemikalierester skal afleveres på genbrugspladsen.'",
              "correct": false
            },
            {
              "option": "'Regnvandskloakken langs vejen er kun til vand uden kemikalier. Sæbevand skal skylles ud i toilettet og maling, olie og kemikalierester skal afleveres på genbrugspladsen.'",
              "correct": true
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-3-4.jpg",
          "q": "Angiv den rigtige placering af komma i sætningen: 'Husk på at der kun må komme vand uden kemikalier ned i regnvandskloakken nå ja og regnvand naturligvis.'",
          "a": [
            {
              "option": "'Husk på, at der kun må komme vand uden kemikalier ned i regnvandskloakken, nå ja og regnvand naturligvis.'",
              "correct": true
            },
            {
              "option": "'Husk på at der kun må komme vand, uden kemikalier ned i regnvandskloakken, nå ja og regnvand naturligvis.'",
              "correct": false
            },
            {
              "option": "'Husk på at der kun må komme vand uden kemikalier ned i regnvandskloakken, nå ja og regnvand naturligvis.'",
              "correct": false
            }
          ]
        },
        {
          "fag": "Dansk",
          "video": "https://www.youtube.com/embed/bHMnxofZIhM",
          "videotekst": "Se filmen Hjælp fisken! - Eventyret om fisken, bækken og regnvandskloakken",
          "image": "Dansk-3-5.jpg",
          "q": "Hvilken af følgende talemåder beskriver, hvordan fisken i eventyret har det, når der kun kommer regnvand i regnvandskloakken?",
          "a": [
            {
              "option": "Død som en sild",
              "correct": false
            },
            {
              "option": "Som ål i mudder",
              "correct": true
            },
            {
              "option": "Gå i fisk",
              "correct": false
            }
          ]
        }
      ],
      "matematik": [
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "illustrations-07.png",
          "q": "Hvis du vasker vinduer med 5 L vand og sæbe 5 gange om året. Hvor meget sæbevand skal du så hælde i toilettet?",
          "a": [
            {
              "option": "5 L",
              "correct": false
            },
            {
              "option": "10 L",
              "correct": false
            },
            {
              "option": "25 L",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-3-2.jpg",
          "q": "Hvis du kører på genbrugspladsen for at aflevere olierester kl. 10.30, opholder dig på pladsen i 30 minutter før, at du tager hjem, men på vej hjem kører du forbi tanken efter en snack, hvilket tager dig 45 minutter - hvornår er du så hjemme?",
          "a": [
            {
              "option": "Kvart over elleve",
              "correct": false
            },
            {
              "option": "Halv tolv",
              "correct": false
            },
            {
              "option": "Kvart i tolv",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-3-3.jpg",
          "q": "Hvis det regner 730 mm om året i Danmark - hvor meget er det så hver dag, når der er 365 dage på et år?",
          "a": [
            {
              "option": "2 mm",
              "correct": true
            },
            {
              "option": "5 mm",
              "correct": false
            },
            {
              "option": "10 mm",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-3-4.jpg",
          "q": "Hvis fiskene, der må flygte fra vand med sæbe i, svømmer 5 km/t, og der er 10 km til, at den er i sikkerhed, hvor lang tid skal de så svømme?",
          "a": [
            {
              "option": "En halv time",
              "correct": false
            },
            {
              "option": "1 time",
              "correct": false
            },
            {
              "option": "2 timer",
              "correct": true
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-3-5.png",
          "q": "Hvis vaskehallen blander 0,2 L sæbe i 5 L vand til at vaske din bil i, hvad er blandingsforholdet så?",
          "a": [
            {
              "option": "1:10",
              "correct": false
            },
            {
              "option": "1:25",
              "correct": true
            },
            {
              "option": "1:50",
              "correct": false
            }
          ]
        },
        {
          "fag": "Matematik",
          "video": "",
          "videotekst": "",
          "image": "Matematik-3-6.jpg",
          "q": "Hvis vandet i vandløbet ikke er forurenet, så er der 1500 fisk i vandløbet. Hvis der kun er 150 fisk i vandløbet, hvad er den procentvise fald i fiskebestanden?",
          "a": [
            {
              "option": "10%",
              "correct": false
            },
            {
              "option": "15%",
              "correct": false
            },
            {
              "option": "90%",
              "correct": true
            }
          ]
        }
      ]
    }
  ]
}