import questions from '../../questions.json';

const Quiz = new Object();

Handlebars.registerHelper("inc", function (value, options) {
  return parseInt(value) + 1;
});

Handlebars.registerHelper("length", function (array, options) {
  return array.length;
});

Handlebars.registerHelper('setIndex', function (value) {
  this.index = Number(value + 1); //I needed human readable index, not zero based
});

Handlebars.registerHelper('isLevel3', function (value) {
  return value === 3;
});

(function () {

  var pages = document.querySelectorAll('.page-wrapper');

  init(Quiz);

  /*
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.question) {
    var step = parseInt(params.question);
    gotoQuestion(step);
  } else {
    if (getCookie('currentQuestion') !== "") {
      var val = parseInt(getCookie('currentQuestion'));
      gotoQuestion(val);
    } else {
      gotoQuestion(1);
    }
  }
  */

  questions.levels.forEach(function (level, index) {
    var template = Handlebars.templates.level;

    for (const [sub, que] of Object.entries(level)) {
      document.getElementById('pageList').insertAdjacentHTML("beforeend", template({
        level: index + 1,
        questions: que,
        subject: sub
      }));
    }

    updateQuiz(Quiz);
  });

  document.querySelector('#choose-level').addEventListener('click', function (page) {
    var nextPage = document.querySelector('#page-levels');

    pages.forEach(element => {
      element.classList.remove('active');
    });

    nextPage.classList.add('active');

    document.body.style.backgroundImage = "none";
  });

  document.querySelector('#level-select').addEventListener('change', function (e) {
    var level = parseInt(e.target.value);
    setLevel(Quiz, level);
    // setQuestion(Quiz, 1);

    updateQuiz(Quiz);

    /*
    var nextPage = document.querySelector('#page-level'+ level);

    pages.forEach(element => {
      element.classList.remove('active');
    });

    nextPage.classList.add('active');
    */
  });

  document.querySelector('#subject-select').addEventListener('change', function (e) {
    var subject = e.target.value;

    setSubject(Quiz, subject);
    setQuestion(Quiz, 1);
    updateQuiz(Quiz);
  });

  /*
  document.querySelectorAll('#reset-button').forEach(element => {
    element.addEventListener('click', function() {
      console.log('reset', Quiz);
      setLevel(Quiz, 0);
      setQuestion(Quiz, 0);

      updateQuiz(Quiz);
    });
  });

  document.querySelectorAll('#next-button').forEach(element => {
    element.addEventListener('click', function() {
      console.log('Update', Quiz);

      updateQuiz(Quiz);
    });
  });

  document.querySelectorAll('.btn-correct').forEach(element => {
    element.addEventListener('click', function(e) {
      element.classList.add('disabled');

      getSiblings(e.target).forEach(element => {
        element.classList.add('disabled');
      });
      Quiz.currentQuestion++;
    });
  });
  */

})();

document.body.addEventListener('click', function (event) {
  if (event.target.id == 'reset-button') {
    setLevel(Quiz, 0);
    setSubject(Quiz, 0);
    setQuestion(Quiz, 0);
    updateQuiz(Quiz);
    setCutout('default');

    document.body.style.backgroundImage = "";
    document.querySelector('#level-select').value = "";
    document.querySelector('#subject-select').value = "";

    // disable all checks reenable all buttons
    var allButtons = document.querySelectorAll('.btn');
    var allChecks = document.querySelectorAll('.btn-check');

    allButtons.forEach(element => {
      element.classList.remove('disabled');
    });

    allChecks.forEach(element => {
      element.checked = false;
    });
  };

  if (event.target.id == 'next-button') {
    updateQuiz(Quiz);
  };

  if (event.target.classList.contains('btn-correct')) {
    event.target.classList.add('disabled');

    getSiblings(event.target).forEach(element => {
      if (element.classList.contains('btn')) {
        element.classList.add('disabled');
      }
    });
    Quiz.currentQuestion++;
  }
});

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let getSiblings = function (e) {
  // for collecting siblings
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;
  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

function init(Quiz) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Get current state
  let currentLevel = getLevel(params);
  let currentSubject = getSubject(params);
  let currentQuestion = getQuestion(params);

  // Update state
  setLevel(Quiz, currentLevel);
  setSubject(Quiz, currentSubject);
  setQuestion(Quiz, currentQuestion);

  updateQuiz(Quiz);
}

function updateQuiz(Quiz) {
  var levelIndicator = "";

  document.querySelectorAll('.page-wrapper').forEach(element => {
    element.classList.remove('active');
  });

  switch (Quiz.currentLevel) {
    case 1:
      levelIndicator = "Niveau 1";
      document.querySelector('#page-subject').classList.add('active');
      /* if (document.querySelector('#page-level1')) {
        document.querySelector('#page-level1').classList.add('active');
      } */
      break;

    case 2:
      levelIndicator = "Niveau 2";
      document.querySelector('#page-subject').classList.add('active');
      /* if (document.querySelector('#page-level2')) {
        document.querySelector('#page-level2').classList.add('active');
      } */
      break;

    case 3:
      levelIndicator = "Niveau 3";
      document.querySelector('#page-subject').classList.add('active');
      /* if (document.querySelector('#page-level3')) {
        document.querySelector('#page-level3').classList.add('active');
      } */
      break;

    default:
      levelIndicator = "";
      document.querySelector('#page-main').classList.add('active');
  }

  if (Quiz.currentSubject !== "" && Quiz.currentSubject !== 0) {
    if ( document.querySelector('#page-level'+ Quiz.currentLevel +'-'+ Quiz.currentSubject) ) {
      document.querySelector('#page-level'+ Quiz.currentLevel +'-'+ Quiz.currentSubject).classList.add('active');
      document.querySelector('#page-subject').classList.remove('active');
    }
  }

  if (Quiz.currentQuestion > 0) {
    var nextQuestion = document.querySelector('#page-level' + Quiz.currentLevel + '-'+ Quiz.currentSubject +'-step' + Quiz.currentQuestion);
    var questionCount = questions.levels[Quiz.currentLevel-1][Quiz.currentSubject].length;

    if ( Quiz.currentQuestion > questionCount ) {
      document.querySelectorAll('.step').forEach(element => {
        element.classList.remove('active')
      });

      document.querySelector('#page-level' + Quiz.currentLevel + '-'+ Quiz.currentSubject +'-summary').classList.add('active');

      document.querySelectorAll('.step header .current').forEach(element => {
        element.innerHTML = "Tillykke";
      });

      setQuestion(Quiz, 0);

      const nextButtons = document.querySelectorAll('#next-button');
      nextButtons.forEach(element => {
        element.style.display = "none";
      });

    } else if (nextQuestion) {
      document.querySelectorAll('.step').forEach(element => {
        element.classList.remove('active')
      });
      nextQuestion.classList.add('active');

      let subject = nextQuestion.dataset.subject;
      setCutout(subject);

      document.querySelectorAll('.step header .current').forEach(element => {
        element.innerHTML = Quiz.currentQuestion;
      });

      const nextButtons = document.querySelectorAll('#next-button');
      nextButtons.forEach(element => {
        element.style.display = "block";
      });
    };
  }

  document.querySelectorAll('.level-indicator').forEach(element => {
    element.innerHTML = levelIndicator;
  });

  /* document.querySelectorAll('.status-bar-progress').forEach(element => {
    element.style.width = (Quiz.currentQuestion / 10) * 100 + '%';
  }); */

  // update params
  if (history.pushState) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?level=' + Quiz.currentLevel + '&subject=' + Quiz.currentSubject + '&question=' + Quiz.currentQuestion;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

function getLevel(params) {
  var currentLevel;
  if (params.level) {
    currentLevel = parseInt(params.level);
  } else {
    if (getCookie('currentLevel') !== "") {
      currentLevel = parseInt(getCookie('currentLevel'));
    } else {
      currentLevel = 0;
    }
  }
  return currentLevel;
}

function setLevel(Quiz, level) {
  if (level > 5) { level == 0 };

  setCookie('currentLevel', level, 2);
  Quiz.currentLevel = level;

  return true;
}

function getSubject(params) {
  var currentSubject;
  if (params.subject) {
    currentSubject = params.subject;
  } else {
    if (getCookie('currentSubject') !== "") {
      currentSubject = getCookie('currentSubject');
    } else {
      currentSubject = "";
    }
  }

  return currentSubject;
}

function setSubject(Quiz, subject) {
  if (subject === 0) {
    subject = "";
  }

  setCookie('currentSubject', subject, 2);
  Quiz.currentSubject = subject;

  return true;
}

function getQuestion(params) {
  var currentQuestion;
  if (params.question) {
    currentQuestion = parseInt(params.question);
  } else {
    if (getCookie('currentQuestion') !== "") {
      currentQuestion = parseInt(getCookie('currentQuestion'));
    } else {
      currentQuestion = 0;
    }
  }
  return currentQuestion;
}

function setQuestion(Quiz, question) {
  setCookie('currentQuestion', question, 2);
  Quiz.currentQuestion = question;
  return true;
}

function setCutout(subject) {
  if (subject){
    let cutouts = document.querySelectorAll('.cutouts .cutout');
    let nextCutout = document.querySelector('.cutouts .cutout[data-subject=' + subject + ']');

    if (nextCutout) {
      cutouts.forEach(element => {
        element.classList.remove('active');
      });

      nextCutout.classList.add('active');
    }
  }
}
